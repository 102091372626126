import React, { PureComponent } from "react";
import Slider from "react-slick";

interface CarouselProps {
  items: React.ReactNode[];
}

export class Carousel extends PureComponent<CarouselProps> {
  setSlidesToShow = () => {
    if (window.innerWidth >= 2400) {
      return 5;
    } else if (window.innerWidth >= 1920) {
      return 4;
    } else if (window.innerWidth >= 1360) {
      return 3;
    } else {
      return 2;
    }
  };

  render() {
    const { items } = this.props;
    const settings = {
      dots: false,
      infinite: items.length > 5,
      speed: 250,
      slidesToScroll: 5,
      slidesToShow: this.setSlidesToShow(),
      autoplay: true,
      arrows: false
    };

    return <Slider {...settings}>{items}</Slider>;
  }
}
