import ApplicationState from "../models/application-state";
import Game from "../models/game";
import SocketContext from "../context/socket-context";
import GameService from "../services/game-service";
import { outgoingMessageTypes } from "../constants/message-types";
import { Box } from "@material-ui/core";
import React, { Component, RefObject } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

interface GameScreenProps {
  selectedGame: Game;
}

interface GameScreenState {
  currentPlayer: string;
  messageVisible: boolean;
  message: string;
}

class GameScreen extends Component<GameScreenProps, GameScreenState> {
  static contextType = SocketContext;
  context!: React.ContextType<typeof SocketContext>;
  private gameArea: RefObject<HTMLIFrameElement> = React.createRef();

  componentDidMount() {
    GameService.setGameWindow(this.gameArea.current?.contentWindow);
    const socket = this.context;
    GameService.onGameReady(() => socket?.emit(outgoingMessageTypes.gameReady));
    GameService.onGameOver(() => socket?.emit(outgoingMessageTypes.gameOver));
  }

  render() {
    return (
      <>
        {this.props.selectedGame && (
          <Box width={1} overflow="hidden">
            <Box position="relative">
              <iframe
                ref={this.gameArea}
                title="game-area"
                src={this.props.selectedGame.path}
                style={{
                  border: 0,
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  height: "100vh"
                }}
              />
            </Box>
          </Box>
        )}
        {!this.props.selectedGame && <Redirect to="/" />}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState): GameScreenProps => ({
  selectedGame: state.selectedGame
});

export default connect<GameScreenProps, void, {}, ApplicationState>(mapStateToProps)(GameScreen);
