import { RESET, SELECT_GAME, UPDATE_GAME_CONFIGURATION } from "../actions/action-types";
import { Reducer } from "redux";

const selectedGame: Reducer = (state = null, action) => {
  switch (action.type) {
    case SELECT_GAME:
      return action.game;
    case UPDATE_GAME_CONFIGURATION:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...action.values
        }
      };
    case RESET:
      return null;
    default:
      return state;
  }
};

export default selectedGame;
