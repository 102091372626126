import { UPDATE_GAME_LIST } from "../actions/action-types";
import { Reducer } from "redux";

const gameList: Reducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_GAME_LIST:
      return action.gameList;
    default:
      return state;
  }
};

export default gameList;
