import Game from "../models/game";
import GameDetails from "./game-details";
import { GameSkeleton } from "./game-skeleton";
import React, { PureComponent } from "react";

interface SelectedGameProps {
  game?: Game;
}

export class SelectedGame extends PureComponent<SelectedGameProps> {
  render() {
    const { game } = this.props;
    return (
      <>
        {game && <GameDetails game={game} />}
        {!game && <GameSkeleton />}
      </>
    );
  }
}
