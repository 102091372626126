import reducers from "./reducers";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";

export const configureStore = (emit: Function): Store => {
  const middleware = [thunk.withExtraArgument({ emit })];
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    reducers,
    {
      code: null,
      selectedGame: null,
      sbcStatus: "offline",
      gameList: []
    },
    composeEnhancers(applyMiddleware(...middleware))
  );
};
