import Game from "../models/game";
import { renderNumberOfPlayers } from "../utils/number-of-players";
import { dummyImgUrl } from "../constants/config";
import { Box, Typography } from "@material-ui/core";
import React, { PureComponent } from "react";
import PersonIcon from "@material-ui/icons/Person";
import { withTheme, Theme } from "@material-ui/core/styles";

interface GameDetailsProps {
  game: Game;
  theme: Theme;
}

export class GameDetails extends PureComponent<GameDetailsProps> {
  render() {
    const { game } = this.props;
    const gameOverview = game.screenshots.length > 0 ? game.screenshots[0] : dummyImgUrl;

    return (
      <Box
        flex="1"
        display="flex"
        style={{
          backgroundImage: `url(${gameOverview})`,
          backgroundSize: "cover",
          borderRadius: 24,
          boxShadow: `inset 24vw 0 60px -30px rgb(0 0 0 / 64%)`
        }}
      >
        <Box
          width="28%"
          display="flex"
          flexDirection="column"
          style={{
            paddingLeft: "3%",
            paddingTop: "3%"
          }}
        >
          <Typography component="div" variant="h6" style={{ color: "#929C8C" }}>
            SELECTED GAME
          </Typography>
          <Typography variant="h3" style={{ fontWeight: "bold" }} color="textSecondary">
            {game.name}
          </Typography>
          <Typography component="div" variant="h6" style={{ fontWeight: "bold" }} color="textSecondary">
            <PersonIcon fontSize="default" style={{ verticalAlign: "middle" }} />{" "}
            {renderNumberOfPlayers(game.numberOfPlayers)}
          </Typography>
          <Typography component="div" variant="h5" style={{ color: this.props.theme.palette.text.disabled }}>
            {game.description}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default withTheme<typeof GameDetails>(GameDetails);
