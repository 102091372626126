import React, { PureComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { withTheme, Theme } from "@material-ui/core/styles";

interface ErrorBoxProps {
  message: string;
  theme: Theme;
}

class ErrorBox extends PureComponent<ErrorBoxProps> {
  render() {
    const { message } = this.props;
    return (
      <Box
        mx="24px"
        display="flex"
        flexDirection="row"
        style={{
          backgroundColor: "#1B1C1B",
          borderRadius: 8,
          marginBottom: 24,
          marginTop: 12,
          borderLeft: `8px solid ${this.props.theme.palette.error.main}`
        }}
      >
        <ErrorIcon
          style={{
            color: this.props.theme.palette.error.main,
            fontSize: "2.5rem",
            marginLeft: 20,
            marginTop: "auto",
            marginBottom: "auto"
          }}
        />
        <Typography
          component="span"
          variant="h5"
          style={{
            color: this.props.theme.palette.error.main,
            marginLeft: 20,
            marginBottom: 24,
            marginTop: 24,
            marginRight: 16
          }}
        >
          {message}
        </Typography>
      </Box>
    );
  }
}

export default withTheme<typeof ErrorBox>(ErrorBox);
