import { loadGames, requestCode } from "../store/actions/action-creators";
import ApplicationState from "../models/application-state";
import { getScreenshots } from "../store/selectors";
import ErrorBox from "../components/error-box";
import { withTheme } from "@material-ui/core/styles";
import { Box, Typography, Paper, Badge, Theme } from "@material-ui/core";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { QRCode } from "react-qrcode-logo";

const scoliaURL = "https://social.scoliadarts.com";

interface StartScreenProps {
  code: string;
  requestCode: Function;
  loadGames: Function;
  screenshots: string[];
  sbcStatus: string;
  theme: Theme;
}

interface StartScreenState {
  loading: boolean;
}

export class StartScreen extends PureComponent<StartScreenProps, StartScreenState> {
  constructor(props: StartScreenProps) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.props.requestCode();
    this.props.loadGames().then(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <>
        {!this.state.loading && (
          <Box
            width={1}
            height="100vh"
            style={{
              backgroundImage: "url(" + process.env.PUBLIC_URL + "/background.jpg)",
              backgroundSize: "cover"
            }}
          >
            <div style={{ height: "100%", width: "100%", backgroundColor: "rgba(27, 28, 27, 0.6)", overflowY: "auto" }}>
              <Box textAlign="center" style={{ height: "36%" }}>
                <img src="scoliaLogo.png" alt="logo" style={{ width: "auto", height: "100%" }} />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Paper style={{ height: "auto", width: 600 }}>
                  <Typography
                    variant="h4"
                    component="div"
                    align="center"
                    style={{ fontWeight: "bold", marginTop: 46, marginBottom: 28 }}
                    color="textSecondary"
                  >
                    WANT TO PLAY?
                  </Typography>
                  <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Badge
                      style={{
                        backgroundColor: "#AAAAAA",
                        height: 32,
                        width: 32,
                        borderRadius: 16,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="h5" style={{ fontWeight: "bold", color: "#1B1C1B" }}>
                        1
                      </Typography>
                    </Badge>
                    <Typography
                      component="div"
                      variant="h5"
                      align="center"
                      style={{ color: this.props.theme.palette.text.disabled, marginLeft: 12 }}
                    >
                      Visit:
                    </Typography>
                  </Box>

                  <Typography
                    component="div"
                    variant="h4"
                    align="center"
                    style={{ fontWeight: "bold", marginTop: 12, marginBottom: 24 }}
                    color="textPrimary"
                  >
                    SOCIAL.SCOLIADARTS.COM
                  </Typography>
                  <Box textAlign="center" marginBottom="24px">
                    <QRCode value={scoliaURL} />
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Badge
                      style={{
                        backgroundColor: "#AAAAAA",
                        height: 32,
                        width: 32,
                        borderRadius: 16,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="h5" style={{ fontWeight: "bold", color: "#1B1C1B" }}>
                        2
                      </Typography>
                    </Badge>
                    <Typography
                      component="div"
                      variant="h5"
                      align="center"
                      style={{ color: this.props.theme.palette.text.disabled, marginLeft: 12 }}
                    >
                      Enter this code:
                    </Typography>
                  </Box>
                  {this.props.sbcStatus === "online" && (
                    <Typography
                      variant="h4"
                      component="div"
                      align="center"
                      style={{ fontWeight: "bold", marginTop: 12, marginBottom: 46 }}
                      color="textPrimary"
                    >
                      {this.props.code}
                    </Typography>
                  )}
                  {this.props.sbcStatus === "in_use" && (
                    <ErrorBox message="Could not establish connection with the server. Another Display Device might be attached to the board" />
                  )}
                  {this.props.sbcStatus === "offline" && <ErrorBox message="The board is currently offline." />}
                </Paper>
              </Box>
            </div>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  code: state.code,
  screenshots: getScreenshots(state),
  sbcStatus: state.sbcStatus
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ requestCode, loadGames }, dispatch);

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(StartScreen));
