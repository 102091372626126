import Game from "../models/game";
import { Carousel } from "./carousel";
import GameListItem from "./game-list-item";
import { Container } from "@material-ui/core";
import React, { PureComponent } from "react";

interface GameListProps {
  games: Game[];
  selectedGame?: Game;
  screenWidth?: number;
}

export class GameList extends PureComponent<GameListProps> {
  render() {
    const { games, selectedGame } = this.props;

    return (
      <Container maxWidth="xl" style={{ marginTop: "auto" }}>
        <Carousel
          items={games.map(game => (
            <GameListItem
              game={game}
              isSelected={selectedGame && selectedGame.gameId === game.gameId}
              key={game.gameId}
            />
          ))}
        />
      </Container>
    );
  }
}
