import { GameList } from "../components/game-list";
import { SelectedGame } from "../components/selected-game";
import ApplicationState from "../models/application-state";
import Game from "../models/game";
import { Box } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

interface GameListScreenProps {
  selectedGame?: Game;
  gameList: Game[];
}

export class GameListScreen extends Component<GameListScreenProps> {
  state = { width: 0, height: 0 };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  setGameListPadding = () => {
    let leftPaddingInPercent = 0;

    if (this.state.width < 1360) {
      leftPaddingInPercent = (this.state.width - 432 * 2) / 3 - 23.5;
      return leftPaddingInPercent + "px";
    } else if (this.state.width <= 1438) {
      leftPaddingInPercent = 8.5;
      return leftPaddingInPercent + "px";
    } else if (this.state.width < 1920) {
      leftPaddingInPercent = (this.state.width - 432 * 3) / 4 - 23.5;
      return leftPaddingInPercent + "px";
    } else if (this.state.width < 2380) {
      leftPaddingInPercent = (this.state.width - 432 * 4) / 5 - 23.5;
      return leftPaddingInPercent + "px";
    } else if (this.state.width >= 2380) {
      leftPaddingInPercent = (this.state.width - 432 * 5) / 6 - 23.5;
      return leftPaddingInPercent + "px";
    }
  };

  render() {
    const { selectedGame, gameList } = this.props;

    return (
      <Box
        width={1}
        height="100vh"
        style={{
          backgroundImage: "url(" + process.env.PUBLIC_URL + "/game_list_background.png)",
          backgroundSize: "cover"
        }}
      >
        <Box width={1} height="80%" display="flex" flexDirection="row">
          <Box width="25%" display="flex" flexDirection="column" alignItems="center">
            <Box alignItems="center" display="flex">
              <img src="scoliaLogo.png" alt="logo" width="350px" height="360px" />
            </Box>
            <Box alignItems="center" display="flex"></Box>
          </Box>
          <Box display="flex" flex="1" width="75%">
            <Box display="flex" flex="1" style={{ marginTop: "6%", marginRight: "4%", marginBottom: "2%" }}>
              <SelectedGame game={selectedGame} />
            </Box>
          </Box>
        </Box>
        <Box
          width={1}
          height="20%"
          display="flex"
          alignItems="center"
          style={{ paddingLeft: this.setGameListPadding() }}
        >
          <GameList selectedGame={selectedGame} games={gameList} screenWidth={this.state.width} />
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  selectedGame: state.selectedGame,
  gameList: state.gameList
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GameListScreen);
