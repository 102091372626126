import code from "./code";
import gameList from "./game-list";
import selectedGame from "./selected-game";
import sbcStatus from "./sbc-status";
import { combineReducers } from "redux";

export default combineReducers({
  selectedGame,
  gameList,
  code,
  sbcStatus
});
