import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import GameListScreen from "./containers/game-list-screen";
import GameScreen from "./containers/game-screen";
import StartScreen from "./containers/start-screen";
import LoadGameScreen from "./containers/load-game-screen";
import { initSocket, initEventHandler } from "./socket";
import { configureStore } from "./store";
import theme from "./theme";
import SocketContext from "./context/socket-context";
import { outgoingMessageTypes } from "./constants/message-types";
import { Store } from "redux";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import React, { Component } from "react";
import { CssBaseline, ThemeProvider, withStyles } from "@material-ui/core";
import * as qs from "query-string";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";

const GlobalCss = withStyles({
  "@global": {
    ".MuiSkeleton-root": {
      background: "grey"
    }
  }
})(() => null);

interface AppComponentState {
  initialized: boolean;
}

class App extends Component<RouteComponentProps, AppComponentState> {
  private socket!: SocketIOClient.Socket;
  private store!: Store;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { initialized: false };
    this.handleUnload = this.handleUnload.bind(this);
  }

  notify = () => {
    toast("This resolution is not supported. Please use in FullHD device for best user experience.", {
      style: { backgroundColor: "#f1c40f", color: "#000", fontWeight: "bold" },
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  checkOrientation = () => {
    if (window.matchMedia("(orientation: landscape)").matches) {
      this.notify();
    }
  };

  handleUnload = () => {
    this.socket.emit(outgoingMessageTypes.reload);
  };

  checkDimensionsOnLoad = () => {
    if (window.innerHeight > window.innerWidth) {
      this.notify();
    }
  };

  componentDidMount() {
    const history = this.props.history;
    const { serialNumber } = qs.parse(this.props.location.search);
    this.socket = initSocket(serialNumber as string);
    const emit = (type: string, payload: any) => this.socket.emit(type, payload);
    this.store = configureStore(emit);
    initEventHandler(this.store, history, this.socket);
    this.setState({ initialized: true });

    this.checkDimensionsOnLoad();
    window.addEventListener("orientationchange", this.checkOrientation);
    window.addEventListener("beforeunload", this.handleUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.checkOrientation);
    window.removeEventListener("beforeunload", this.handleUnload);
  }

  CustomCloseButton = ({ closeToast }: any) => <CloseIcon onClick={closeToast}></CloseIcon>;

  render() {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          closeButton={this.CustomCloseButton}
          draggable
        />
        {this.state.initialized && (
          <Provider store={this.store}>
            <SocketContext.Provider value={this.socket}>
              <ThemeProvider theme={theme}>
                <GlobalCss />
                <CssBaseline>
                  <Switch>
                    <Route path="/game-list">
                      <GameListScreen />
                    </Route>
                    <Route path="/game">
                      <GameScreen />
                    </Route>
                    <Route path="/load-game">
                      <LoadGameScreen />
                    </Route>
                    <Route path="/">
                      <StartScreen />
                    </Route>
                  </Switch>
                </CssBaseline>
              </ThemeProvider>
            </SocketContext.Provider>
          </Provider>
        )}
      </>
    );
  }
}

export default withRouter(App);
