import { createMuiTheme } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { responsiveFontSizes } from "@material-ui/core/styles";

const themeConfig: ThemeOptions = {
  typography: {
    fontFamily: "Roboto Condensed"
  },
  palette: {
    primary: {
      main: "#5AAB30",
      light: "#9AC541"
    },
    background: {
      default: "#0C0C0C",
      paper: "#282928"
    },
    text: {
      primary: "#9AC541",
      secondary: "#FFFFFF",
      disabled: "#DADADA"
    },
    error: {
      main: "#F25F5F"
    }
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 24
      }
    },
    MuiChip: {
      root: {
        height: 128,
        borderRadius: 128 / 2,
        fontSize: 48,
        "& $avatar": {
          width: 96,
          height: 96,
          fontSize: 32,
          marginLeft: 18
        }
      },
      outlined: {
        "& $avatar": {
          marginLeft: 18
        }
      }
    }
  }
};

export default responsiveFontSizes(createMuiTheme(themeConfig));
