import { SBC_STATUS_CHANGED } from "../actions/action-types";
import { Reducer } from "redux";

const sbcStatus: Reducer = (state = "offline", action: { type: string; status?: string }) => {
  switch (action.type) {
    case SBC_STATUS_CHANGED:
      return action.status;
    default:
      return state;
  }
};

export default sbcStatus;
