import * as apiService from "../../services/api-service";
import { outgoingMessageTypes } from "../../constants/message-types";
import ApplicationState from "../../models/application-state";
import SbcStatus from "../../models/sbc-status";
import {
  RESET,
  SELECT_GAME,
  SET_CODE,
  UPDATE_GAME_CONFIGURATION,
  UPDATE_GAME_LIST,
  SBC_STATUS_CHANGED
} from "./action-types";
import { Dispatch } from "redux";

export const updateGameConfiguration = (values: any) => ({
  type: UPDATE_GAME_CONFIGURATION,
  values
});
export const setCode = (code: string | null) => ({ type: SET_CODE, code });

export const reset = () => ({ type: RESET });

export const requestCode = () => (dispatch: Dispatch, getState: Function, { emit }: { emit: Function }) => {
  dispatch(setCode(null));
  emit(outgoingMessageTypes.codeRequest);
};

export const selectGame = (gameId: string) => (dispatch: Dispatch, getState: Function) => {
  const state: ApplicationState = getState();
  const game = state.gameList.find(g => g.gameId === gameId);

  if (game) {
    dispatch({ type: SELECT_GAME, game });
  }
};

export const loadGames = () => async (dispatch: Dispatch) => {
  let games;

  try {
    games = await apiService.loadGameList();
  } catch (e) {
    console.log(e);
    games = [];
  }

  dispatch({
    type: UPDATE_GAME_LIST,
    gameList: games
  });
};

export const sbcStatusChanged = ({ status }: SbcStatus) => (dispatch: Dispatch) => {
  dispatch({ type: SBC_STATUS_CHANGED, status });
};
