import Game from "../models/game";
import { renderNumberOfPlayers } from "../utils/number-of-players";
import { Box, Typography, Paper, Chip } from "@material-ui/core";
import React, { PureComponent } from "react";
import PersonIcon from "@material-ui/icons/Person";
import { withTheme, Theme } from "@material-ui/core/styles";

interface GameListItemProps {
  game: Game;
  isSelected?: boolean;
  theme: Theme;
}

export class GameListItem extends PureComponent<GameListItemProps> {
  render() {
    const { game, isSelected } = this.props;

    return (
      <Paper
        style={{
          height: "112px",
          border: isSelected ? `4px solid ${this.props.theme.palette.primary.light}` : "4px solid transparent",
          marginTop: 18,
          display: "flex",
          justifyContent: "center",
          maxWidth: "440px",
          marginRight: "24px"
        }}
      >
        {isSelected && (
          <Box height="32px" position="absolute" justifyContent="center" display="flex" style={{ marginTop: -18 }}>
            <Chip
              label={
                <Typography component="div" variant="h6" style={{ fontWeight: "bold", color: "#1B1C1B" }}>
                  Selected
                </Typography>
              }
              style={{
                backgroundColor: this.props.theme.palette.primary.light,
                height: 32,
                marginLeft: 16,
                marginRight: 16
              }}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="row" flex="1">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mx="24px" my="24px">
              <img src={game.icon} alt={game.name} style={{ height: "64px", width: "64px" }} />
            </Box>
          </Box>
          <Box minWidth="0" maxWidth="200px" display="flex" flex="1" alignItems="center">
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden" }}
              color="textSecondary"
            >
              {game.name}
            </Typography>
          </Box>
          <Box width="auto" display="flex" justifyContent="flex-end" alignItems="center">
            <Typography
              component="span"
              variant="h5"
              style={{ fontWeight: "bold", color: "#929C8C", marginRight: 24, marginLeft: 24 }}
            >
              <PersonIcon fontSize="default" style={{ verticalAlign: "middle" }} />{" "}
              {renderNumberOfPlayers(game.numberOfPlayers)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default withTheme<typeof GameListItem>(GameListItem);
