import { incomingMessageTypes } from "../constants/message-types";
import { URI } from "../constants/config";
import { EventHandler } from "./event-handler";
import { History } from "history";
import { Store } from "redux";
import io from "socket.io-client";

export const initSocket = (serialNumber: string): SocketIOClient.Socket =>
  io(URI, { query: { type: "display", token: serialNumber } });

export const initEventHandler = (store: Store, history: History, socket: SocketIOClient.Socket) => {
  const emit = (type: string, payload: any) => socket.emit(type, payload);
  const handler = new EventHandler(store, history, emit);
  Object.values(incomingMessageTypes).forEach(message => {
    socket.on(message, (payload: any) => handler.handle(message, payload));
  });
};
